/* eslint-disable max-len */
import React from 'react';

const Home = () => (
  <div className="home">
    <h3 className="home-intro">Welcome to our page!</h3>
    <p className="home-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat augue quis tellus eleifend ultricies. Donec eleifend semper tristique. Fusce erat est, lobortis id justo ac, ullamcorper faucibus neque. Ut at nisi vitae erat tempus vehicula. Fusce eget neque eu lectus semper viverra.</p>
    <p className="home-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat augue quis tellus eleifend ultricies. Donec eleifend semper tristique. Fusce erat est, lobortis id justo ac, ullamcorper faucibus neque. Ut at nisi vitae erat tempus vehicula. Fusce eget neque eu lectus semper viverra.</p>
  </div>
);
export default Home;
